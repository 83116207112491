import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  IconButton,
  Tooltip,
} from '@mui/material';

interface ViewTokenModalProps {
  open: boolean;
  token: string;
  handleClose: () => void;
}

export const ViewTokenModal = ({ open, token, handleClose }: ViewTokenModalProps) => {
  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(token);
    } catch (err) {
      // No Op
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle>Auth Token</DialogTitle>
      <DialogContent>
        <TextField
          value={token}
          fullWidth
          multiline
          rows={4}
          InputProps={{
            readOnly: true,
            endAdornment: (
              <Tooltip title="Copy to clipboard">
                <IconButton onClick={handleCopy} edge="end">
                  <ContentCopyIcon />
                </IconButton>
              </Tooltip>
            ),
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};
