import { useAuth0 } from '@auth0/auth0-react';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import SettingsIcon from '@mui/icons-material/Settings';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import { AppBar, Grid, IconButton, ListItemIcon, Menu, MenuItem, Toolbar, Typography } from '@mui/material';
import { useState } from 'react';

import { ViewTokenModal } from 'src/components/Modals/ViewTokenModal';
import { usePageTitleContext } from 'src/contexts/pageTitleContext';
import { useHandleMenu } from 'src/hooks';

interface NavBarProps {
  drawerWidth: number;
}

export const NavBar = ({ drawerWidth = 180 }: NavBarProps) => {
  const { user, logout, getAccessTokenSilently } = useAuth0();
  const { pageTitle } = usePageTitleContext();
  const { open, anchorEl, handleMenuOpen, handleMenuClose } = useHandleMenu();
  const [viewTokenModalOpen, setViewTokenModalOpen] = useState(false);
  const [token, setToken] = useState('');

  const handleViewToken = async () => {
    try {
      const accessToken = await getAccessTokenSilently();
      setToken(accessToken);
      setViewTokenModalOpen(true);
    } catch (err) {
      // No Op
    }
  };

  const handleLogoutClick = () => {
    logout({ logoutParams: { returnTo: window.location.origin } });
  };

  return (
    <>
      <AppBar position="fixed" sx={{ width: `calc(100% - ${drawerWidth}px)`, ml: `${drawerWidth}px`, boxShadow: 1 }}>
        <Toolbar sx={{ backgroundColor: 'primary.main', color: 'white' }}>
          <Grid container>
            <Grid item>
              <Typography style={{ fontWeight: 'bold' }} variant="subtitle1">
                {pageTitle}
              </Typography>
            </Grid>
          </Grid>
          <Grid container justifyContent="flex-end" alignItems="center">
            <Grid item>
              <Typography variant="overline">WAEV | {user?.nickname ?? 'User'}</Typography>
            </Grid>
            <Grid item>
              <IconButton onClick={handleMenuOpen}>
                <AccountCircleIcon sx={{ color: 'white' }} fontSize="large" />
              </IconButton>
            </Grid>
            <Menu
              anchorEl={anchorEl}
              open={open}
              onClose={handleMenuClose}
              onClick={handleMenuClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: 'visible',
                  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                  mt: 1.5,
                  '& .MuiAvatar-root': {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  '&:before': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: 'background.paper',
                    transform: 'translateY(-50%) rotate(45deg)',
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
              <div>
                <MenuItem disabled>
                  <ListItemIcon>
                    <SettingsIcon />
                  </ListItemIcon>
                  <Typography variant="overline">Settings</Typography>
                </MenuItem>
                <MenuItem onClick={handleViewToken}>
                  <ListItemIcon>
                    <VpnKeyIcon />
                  </ListItemIcon>
                  <Typography variant="overline">View Token</Typography>
                </MenuItem>
                <MenuItem onClick={handleLogoutClick}>
                  <ListItemIcon>
                    <LogoutOutlinedIcon />
                  </ListItemIcon>
                  <Typography variant="overline">Log Out</Typography>
                </MenuItem>
              </div>
            </Menu>
          </Grid>
        </Toolbar>
      </AppBar>
      <ViewTokenModal open={viewTokenModalOpen} token={token} handleClose={() => setViewTokenModalOpen(false)} />
    </>
  );
};
